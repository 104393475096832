import React, { useState, createContext, useEffect } from 'react';

import firebase from 'firebase/app';
import 'firebase/database';

export const DisplayContext = createContext();

const DisplayContextProvider = props => {

    const [challenge, setChallenge] = useState({});
    const [meeting, setMeeting] = useState({});

    const [displayConfigLoaded, setDisplayConfigLoaded] = useState(false);

    useEffect(() => {
        firebase.database().ref('configs/display').on('value', (snapshot) => {
            const data = snapshot.val()

            const configsChallenge = data?.['チャレンジトップ'];
            setChallenge({
                hometatsuFormDisplayed: configsChallenge?.['ほめ達']?.['フォーム'],
                hometatsuZoomDisplayed: configsChallenge?.['ほめ達']?.['ZOOM'],
            });

            const configsMeeting = data?.['店長会議トップ'];
            setMeeting({
                additionalEntroyForm1: configsMeeting?.['追加フォーム1'],
                additionalEntroyForm2: configsMeeting?.['追加フォーム2'],
                additionalEntroyForm3: configsMeeting?.['追加フォーム3'],
                additionalEntroyForm4: configsMeeting?.['追加フォーム4'],
                additionalEntroyForm5: configsMeeting?.['追加フォーム5'],
            });

            setDisplayConfigLoaded(true);
        });
    }, []);

    return (
        <DisplayContext.Provider value={{ challenge, meeting, displayConfigLoaded }}>
            {props.children}
        </DisplayContext.Provider>
    )
}

export default DisplayContextProvider;

import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import 'firebase/storage';

import SpHeader from '../../components/SpHeader';
import PcHeader from '../../components/PcHeader';
import PcFooter from '../../components/PcFooter';

import Loading from '../../components/Loading';
import MeetingPassModal from '../../components/MeegintPassModal';
import ApiHandler from '../../utils/ApiHandler';
import { GoogleFormLinkRow } from '../../components/FormLink';
import { DisplayContext } from '../../context/DisplayContext';

export default function Meeting() {
    const [forms, setForms] = useState({});
    const [modalOpened, isModalOpened] = useState(false);

    const [bannerURL, setBannerURL] = useState('');
    const [formsLoaded, isFormsLoaded] = useState(false);
    const [bannerLoaded, isBannerLoaded] = useState(false);

    const { 
        additionalEntroyForm1,
        additionalEntroyForm2,
        additionalEntroyForm3,
        additionalEntroyForm4,
        additionalEntroyForm5,
    } = useContext(DisplayContext).meeting;

    useEffect(() => {
        document.title = 'SMILE 店長会議TOP';
        new ApiHandler('getForms', data => {
            setForms(data);
            isFormsLoaded(true);
        }).execute({ category: 'meeting' });

        firebase.storage().ref('images/meeting_top_banner.png').getDownloadURL().then(value => {
            setBannerURL(value);
            isBannerLoaded(true);
        });
    }, []);

    const handleClick = (e) => {
        e.preventDefault();
        isModalOpened(true);
    };

    const createLinkList = () => {
        return (
            <ul className="reset_list ly_center">
                <li>
                    <Link to='#' onClick={handleClick} className="pt_0">
                        <p><img src="/asset/img/pages/manager_top/oneteam_webmeeting.png?20240430" alt="One team web meeting"></img></p>
                        <p>会議の視聴はこちら</p>
                    </Link>
                </li>
                <GoogleFormLinkRow
                    formId={forms.applicationForParticipation.formId}
                    windowName='form7'
                    disabled={forms.applicationForParticipation.disabled}
                    title={forms.applicationForParticipation.name}
                    description='会議の参加登録はこちら'
                ></GoogleFormLinkRow>
                {additionalEntroyForm1 ?
                    <GoogleFormLinkRow
                        formId={forms.additionalEntroyForm1.formId}
                        windowName='additionalEntroyForm1'
                        title={forms.additionalEntroyForm1.name}
                        description={forms.additionalEntroyForm1.description}
                    ></GoogleFormLinkRow>
                : <></>}
                {additionalEntroyForm2 ?
                    <GoogleFormLinkRow
                        formId={forms.additionalEntroyForm2.formId}
                        windowName='additionalEntroyForm2'
                        title={forms.additionalEntroyForm2.name}
                        description={forms.additionalEntroyForm2.description}
                    ></GoogleFormLinkRow>
                : <></>}
                {additionalEntroyForm3 ?
                    <GoogleFormLinkRow
                        formId={forms.additionalEntroyForm3.formId}
                        windowName='additionalEntroyForm3'
                        title={forms.additionalEntroyForm3.name}
                        description={forms.additionalEntroyForm3.description}
                    ></GoogleFormLinkRow>
                : <></>}
                {additionalEntroyForm4 ?
                    <GoogleFormLinkRow
                        formId={forms.additionalEntroyForm4.formId}
                        windowName='additionalEntroyForm4'
                        title={forms.additionalEntroyForm4.name}
                        description={forms.additionalEntroyForm4.description}
                    ></GoogleFormLinkRow>
                : <></>}
                {additionalEntroyForm5 ?
                    <GoogleFormLinkRow
                        formId={forms.additionalEntroyForm5.formId}
                        windowName='additionalEntroyForm5'
                        title={forms.additionalEntroyForm5.name}
                        description={forms.additionalEntroyForm5.description}
                    ></GoogleFormLinkRow>
                : <></>}
                <li>
                    <a href="https://31ice.eventos.tokyo/web/portal/360/event/11343" target="_eventos">
                        <h3>ユーザー登録・事前視聴確認</h3>
                        <p>会議を視聴するためのユーザー登録および事前視聴確認はこちら</p>
                    </a>
                </li>
            </ul>
        );
    };

    return (
        <>
            <SpHeader></SpHeader>
            <PcHeader></PcHeader>

            <div className="content ly_is_nav">
                <div className="page_kv">
                    <img src={bannerURL} alt="" className="ly_center"></img>
                </div>

                <div className="width_default_pc1030">
                    <h2 className="mod_page_title">MEETING TOP</h2>
                </div>

                <div className="sp_default">
                    <div className="mod_list mb_40">
                        {forms.applicationForParticipation ? createLinkList() : <></>}
                    </div>
                    <p className="ly_center hide_pc_tab mt_40 mb_40">
                        <img src="/asset/img/common/logo/logo_31.svg" alt="" className="ly_center"></img>
                    </p>
                </div>
            </div>
            <Loading show={formsLoaded === false || bannerLoaded === false} />
            <MeetingPassModal
                classes={`foeShow ${modalOpened ? '' : 'hide'}`}
                handleCancel={() => isModalOpened(false)}
            ></MeetingPassModal>
            <PcFooter></PcFooter>
        </>
    );
}